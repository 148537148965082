import TalkTagService from "@/services/settings/talk/TalkTagService"
import TalkCategoryService from "@/services/settings/talk/TalkCategoryService"
import TalkServicesService from "@/services/settings/talk/TalkServicesService"

export const TYPE_TAG = 1
export const TYPE_SERVICE = 2
export const TYPE_CATEGORY = 3

export const GROUPING_SERVICE_MAPPING = {
    [TYPE_TAG]: TalkTagService,
    [TYPE_SERVICE]: TalkServicesService,
    [TYPE_CATEGORY]: TalkCategoryService,
}

export const GROUPING_ROUTES_MAPPING = {
    [TYPE_TAG]: {
        index: { name: "settings.talk.tags.index" },
        create: { name: "settings.talk.tags.create" },
        edit: { name: "settings.talk.tags.edit" },
    },
    [TYPE_SERVICE]: {
        index: { name: "settings.talk.services.index" },
        create: { name: "settings.talk.services.create" },
        edit: { name: "settings.talk.services.edit" },
    },
    [TYPE_CATEGORY]: {
        index: { name: "settings.talk.categories.index" },
        create: { name: "settings.talk.categories.create" },
        edit: { name: "settings.talk.categories.edit" },
    },
}

export const GROUPING_LABEL_MAPPING = {
    [TYPE_TAG]: "tag",
    [TYPE_SERVICE]: "serviço",
    [TYPE_CATEGORY]: "categoria",
}
