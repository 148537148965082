import Authentication from "@/router/routes/Authentication"
import Companies from "@/router/routes/Companies"
import { ContentDefault, ContentFullHeight } from "@/router/routes/Content"
import Activities from "@/router/routes/Activities"
import Documents from "@/router/routes/Documents"
import Emails from "@/router/routes/Emails"
import Forecast from "@/router/routes/Forecast"
import Messages from "@/router/routes/Messages"
import Integrations from "@/router/routes/Integrations"
import Persons from "@/router/routes/Persons"
import Pipedrive from "@/router/routes/migrators/Pipedrive"
import PipelinesSettings from "@/router/routes/settings/PipelinesSettings"
import Reports from "@/router/routes/Reports"
import Settings from "@/router/routes/settings/Settings"
import Signatures from "@/router/routes/Signatures"
import SignaturesPublic from "@/router/routes/public/SignaturesPublic"
import UserEmailIntegration from "@/router/routes/public/UserEmailIntegration"
import TeamGroups from "@/router/routes/TeamGroups"
import Administration from "@/router/routes/administration/Administration"
import AdministrationAccount from "@/router/routes/administration/Account"
import DealRanking from "@/router/routes/rankings/DealRanking"
import ProductivityRanking from "@/router/routes/rankings/ProductivityRanking"
import PaymentInvoice from "@/router/routes/public/PaymentInvoice"
import PublicCustomFormsNew from "@/router/routes/settings/CustomFormsNewPublic"
import Goals from "@/router/routes/Goals"
import EmailUnsubscribe from "@/router/routes/public/EmailUnsubscribe"
import LandingPage from "@/router/routes/settings/LandingPage"
import Domains from "@/router/routes/settings/Domains"
import Commissions from "@/router/routes/Commissions"
import Talk from "@/router/routes/talk"

export default [
    /* Public */
    {
        path: "/v2/public/",
        component: () => import("@/layouts/PublicLayout"),
        children: [
            ...SignaturesPublic,
            ...UserEmailIntegration,
            ...PaymentInvoice,
            ...PublicCustomFormsNew,
            ...EmailUnsubscribe,
        ],
        meta: {
            isPublic: true,
        },
    },
    /* Nova tela de login e login com SSO */
    ...Authentication,
    /* Nova layout de tabelas */
    {
        path: "/v2/",
        component: () => import("@/layouts/PipeLayoutBase"),
        children: [
            ...Companies,
            ...DealRanking,
            ...ProductivityRanking,
            ...Persons,
        ],
    },
    /* Layout SEM barra no topo */
    {
        path: "/v2/",
        component: () => import("@/layouts/FullHeight"),
        children: [
            ...ContentFullHeight,
            /* Reports */
            ...Reports,
            /* Settings */
            ...PipelinesSettings,
        ],
    },
    /* Layout COM barra e botões */
    {
        path: "/v2/",
        component: () => import("@/layouts/DefaultLayout"),
        children: [
            {
                name: "index",
                path: "",
                component: () => import("@/pages/NotFound"),
            },

            ...Emails,
            ...Documents,
            ...Forecast,
            ...Commissions,
            ...Signatures,
            ...Messages,

            /* Settings */
            ...ContentDefault,
            ...Integrations,
            ...Settings,
            ...Administration,
            ...AdministrationAccount,
            ...TeamGroups, // TeamGroups C1/G1
            ...Activities,
            ...Goals,
            ...LandingPage,
            ...Domains,

            /* Migrators */
            ...Pipedrive,
            {
                path: "notfound",
                name: "notfound",
                component: () => import("@/pages/NotFound"),
            },
        ],
    },
    {
        path: "/v2/",
        component: () => import("@/layouts/EmptyRouterLayout"),
        children: [
            ...Talk,
        ],
    },
    {
        path: "/:catchAll(.*)",
        name: "redirect-notfound",
        redirect: {
            name: "notfound",
        },
    },
]
